import { gql } from '@apollo/client';

export const addBuyer = gql`
mutation ($buyer: BuyerInput!){
  addBuyer (buyer: $buyer) {
    id
    name
    sellers { id, name }
    buyerDetails { key, value }
    buyerPersons { person { id, email } }
  }
}`;

export const getToolsByBuyer = gql`
query getBuyer($id: Int) {
  buyer(id: $id) {
    id
    name
    buyerDetails {
      id
      label
      key
      value
      externalId {
        externalId
        externalIdType
      }
      option {
        id
        key
        label
        description
        value
        inputType
        detailType
        uiElement
        isRequired
        uiOrder
      }
    }
    transactions {
      id
      uuid
      name
      description
      status
      customDataJson
      startDate
      completionDate
      hasContract
      hasPayment
      externalId {
        externalId
      }
      workflowItems {
        status
        workflowExternalIds { externalId }
      }
      activities {
        id
        date
        summary
        activityType
        details
      }
      paymentPlan {
        id
        phases {
          id
          name
          amountDue
          amountPaid
          priority
          completionDate
          status
        }
      }
      process {
        id
        email
        seller { id name }
        bundle { goods { id name }}
      }
    }
    sellers {
      id
      name
      externalId { externalId }
      districtGroups {
        id
        displayName
        isActive
        boundaries {
          id
          eeDistrictId
          eeDistrictName
        }
      }
      optionTemplates {
        id
        isActive
        buyerOptions {
          id
          key
          label
          description
          value
          inputType
          detailType
          uiElement
          isRequired
          uiOrder
        }
        goodOptions {
          id
          category
          learnMoreLink
          good {
            id 
            name
            description
            logo
            isReferral
            isReferralInNewTab
            website
            referralLink
          }
          passthroughTemplate {
            id
            seller {
              id
              name
            }
          }
        }
      }
    }
    buyerPersons {
      id
      person {
        id
        firstName
        middleName
        lastName
        email
        emailOptIn
        phone
        phoneOptIn
      }
      role {
        id
        name
      }
      permissionGroup {
        id 
        name
      } 
    }
  }
}`;

export const getToolTransactions = gql`
query getBuyer($id: Int) {
  buyer(id: $id) {
    id
    name
    buyerDetails {
      id
      label
      key
      value
      externalId {
        externalId
        externalIdType
      }
    option {
      templateId
      }
    }
    transactions {
      id
      name
      description
      status
      startDate
      completionDate
      externalId {
        externalId
      }
      workflowItems {
        status
        name
        startDate
        completionDate
      }
      activities {
        date
        activityType
        transactionStep {
          id
          name
          status
          startDate
          completionDate
        }
      }
      process {
        id
        email
        seller { id name }
        bundle { goods { id name description }}
      }
    }
    buyerPersons {
      id
      person {
        id
        firstName
        middleName
        lastName
        email
        emailOptIn
        phone
        phoneOptIn
      }
      role {
        id
        name
      }
    }
  }
}`;

export const getBuyerTransactions = gql`
  query buyersMostRecentTransaction($id: Int!) {
    buyersMostRecentTransaction(id: $id) {
    id
    name
    buyerRecentTransactions {
      id
      name
      mostRecentTransaction {
        id
        status
        dateModified
      }
    }
  }
}`
